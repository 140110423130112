import { Component, Input } from '@angular/core';
import { Player } from '../../../Models/Player';
import { Session } from '../../../Models/Session';

@Component({
    selector: 'x-hostcard',
    templateUrl: 'HostCard.html',
    styleUrls: ['HostCard.scss']
})
export class HostCard {
    @Input() Player: Player;
    @Input() Session: Session;
}
