﻿import { Output, EventEmitter } from '@angular/core';
import { Log } from '../System/Log';

declare var $;

export abstract class BaseHub {
    protected _connection: any;
    protected _proxy: any;
    protected _proxyName: string;
    public _connectionExists: Boolean;
    public transportType: string;
    @Output('OnCreateSessionResponse') OnCreateSessionResponse = new EventEmitter();

    constructor(proxyName: string, hubUrl: string) {
        this._proxyName = proxyName;
        this._connection = $.hubConnection(hubUrl);
        this._connection.logging = true;
        this._proxy = this._connection.createHubProxy(this._proxyName);
        this._connection.error(error => {
          console.error('SignalR error', error);
        });
        this._connection.disconnected(() => {
          setTimeout(() => {
            this._proxy = this._connection.createHubProxy(this._proxyName);
            this.RegisterOnServerEvents();
            this.StartConnection();
          }, 5000); // Restart connection after 5 seconds.
        });
        this.RegisterOnServerEvents();
        this.StartConnection();
    }

    private StartConnection(): void {
        this._connection.start().done((data) => {
            Log.info('[BaseHub] Now connected ' + data.transport.name + ', connection ID= ' + data.id);
            this._connectionExists = true;
            this.transportType = data.transport.name;
            if (data.transport.name === 'longPolling') {
              // Ping every 10s
              setInterval(() => {
                this._proxy.invoke('ping').fail(() => {
                  // Failed to ping the server, we could either try one more time to ensure we can't reach the server
                  // or we could fail right here.
                  this.StartConnection();
                });
              }, 10000);
            }
        }).fail((error) => {
            Log.error('[BaseHub] Could not connect to hub', error);
        });
    }

    protected abstract RegisterOnServerEvents(): void;

    public AwaitConnection(): Promise<any> {
        return new Promise<any>(function (resolve, reject) {
            this.CheckConnection(resolve, reject, 0);
        }.bind(this));
    }

    private CheckConnection(resolve, reject, time) {
        if (this._connection.state === 1) {
            resolve();
        } else {
            if (time > 30000) {
                Log.info('[BaseHub] CheckConnection::Timed out');
                reject('Connection timed out');
            } else {
                Log.info('[BaseHub] CheckConnection::Still waiting');
                setTimeout(function () {
                    this.CheckConnection(resolve, reject, time + 100);
                }.bind(this), 100);
            }
        }
    }
}
