﻿import { Injectable, Inject } from '@angular/core';
import { Player } from './Player';
import { Session } from './Session';


@Injectable()
export class SessionModel {
    private _player: Player;
    private _session: Session;

    get Player(): Player {
        return this._player;
    }
    set Player(value: Player) {
        this._player = value;
    }

    get Session(): Session {
        return this._session;
    }
    set Session(value: Session) {
        this._session = value;
    }
}