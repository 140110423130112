﻿import { Component, forwardRef, Input, Output, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

const noop = () => { };

@Component({
    selector: 'x-textbox',
    template: `<input #input type="text" [placeholder]="PlaceHolder" [ngModel]="Value" (ngModelChange)="OnModelChangeEvent($event)" (blur)="onTouched" [attr.autofocus]="AutoFocus ? 'autofocus' : null" />`,
    styleUrls: ['TextBox.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TextBox), multi: true }
    ]
})
export class TextBox implements ControlValueAccessor {
    private FValue = '';
    private FOnTouchedCallback: () => void = noop;
    private FOnChangeCallback: (_: any) => void = noop;
    @ViewChild('input') InputElement: HTMLInputElement;
    @Input('PlaceHolder') PlaceHolder: string = "";
    @Input('AutoFocus') AutoFocus: boolean = true;
    @Output('OnModelChange') OnModelChange = new EventEmitter();

    constructor() {
    }

    private OnModelChangeEvent($event) {
        this.Value = $event;
        this.OnModelChange.emit($event);
    }

    /* Begin::ControlValueAccessor (support ngForm) */

    get Value(): any {
        return this.FValue;
    };

    @Input('Value')
    set Value(v: any) {
        if (v !== this.FValue) {
            this.FValue = v;
            this.FOnChangeCallback(v);
        }
    }

    onTouched() {
        this.FOnTouchedCallback();
    }

    writeValue(value: any) {
        this.Value = value;
    }

    registerOnChange(fn: any) {
        this.FOnChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.FOnTouchedCallback = fn;
    }

     /* End::ControlValueAccessor (support ngForm) */
}
