﻿import { Injectable } from '@angular/core';
import { SessionModel } from '../Models/SessionModel';
import { Session } from '../Models/Session';
import { PlayerSession } from '../Models/PlayerSession';
import { Log } from '../Framework/System/Log';

@Injectable()
export class MockSessionService {

    constructor(protected sessionModel: SessionModel) {
    }

    public CreateSession(sessionID?: string, code?: string): Promise<any> {
        Log.info('[MockSessionService] CreateSession', sessionID, code);
        return new Promise<any>((resolve, reject) => {
            const session: Session = Session.GetMock();
            if (sessionID) {
                session.ID = sessionID;
            }
            if (code) {
                session.Code = code;
            }
            this.sessionModel.Session = session;
            resolve(session);
        });
    }

    public GetActiveSession(): Session {
        return this.sessionModel.Session;
    }

    public EndSession(): void {
        this.sessionModel.Session = null;
    }

    public GetSession(sessionID: string, playerSessionID?: string): Promise<any> {
        Log.debug('[MockSessionService] GetSession', sessionID, playerSessionID);
        return new Promise<any>((resolve, reject) => {
            if (playerSessionID !== undefined) {
                if (this.sessionModel.Player === undefined) {
                    const playerSession: PlayerSession = PlayerSession.GetMock();
                    this.sessionModel.Player = playerSession.Player;
                    this.sessionModel.Session = playerSession.Session;
                }
                const result: PlayerSession = new PlayerSession();
                result.Player = this.sessionModel.Player;
                result.Session = this.sessionModel.Session;
                Log.debug('[MockSessionService] GetSession Result::1', result);
                resolve(result);
            } else {
                if (this.sessionModel.Session === undefined) {
                    reject('Session not found');
                }
                Log.debug('[MockSessionService] GetSession Result::2', this.sessionModel.Session);
                resolve(this.sessionModel.Session);
            }
        });
    }

    public SetCardValue(value: number): Promise<any> {
        Log.info('[MockSessionService] SetCardValue', value);
        return new Promise<any>((resolve, reject) => {
            if (this.sessionModel.Player) {
                this.sessionModel.Player.CardValue = value;
                this.sessionModel.Session.AllCardsSubmitted = false; // Debug switch
                const result: PlayerSession = new PlayerSession();
                result.Player = this.sessionModel.Player;
                result.Session = this.sessionModel.Session;
                Log.debug('[MockSessionService] SetCardValue Result', result);
                resolve(result);
            }
            else {
                reject('no player object found');
            }
        });
    }

    public JoinSession(code: string, name: string): Promise<any> {
        Log.info('[MockSessionService] JoinSession', code, name);
        return new Promise<any>((resolve, reject) => {
            const session = this.sessionModel.Session;
            const player = this.sessionModel.Player;
            if (session && player) {
                const playerSession: PlayerSession = new PlayerSession();
                playerSession.Player = player;
                playerSession.Session = session;
                Log.debug('[MockSessionService] JoinSession Result::1', playerSession);
                resolve(playerSession);
            } else {
                const playerSession: PlayerSession = PlayerSession.GetMock();
                this.sessionModel.Player = playerSession.Player;
                playerSession.Player.Name = name;
                this.sessionModel.Session = playerSession.Session;
                Log.debug('[MockSessionService] JoinSession Result::2', playerSession);
                resolve(playerSession);
            }
        });
    }

    public ForceShow(sessionID: string): Promise<any> {
        Log.info('[MockSessionService] ForceShow', sessionID);
        return new Promise<any>((resolve, reject) => {
            if (this.sessionModel.Session) {
                this.sessionModel.Session.Players.forEach(p => {
                    if (p.CardValue < -2) {
                        p.CardValue = -1;
                    }
                });
                this.sessionModel.Session.AllCardsSubmitted = true;
                resolve();
            } else {
                reject('no session object found');
            }
        });
    }

    public Reset(sessionID: string): Promise<any> {
        Log.info('[MockSessionService] Reset', sessionID);
        return new Promise<any>((resolve, reject) => {
            if (this.sessionModel.Session) {
                this.sessionModel.Session.Players.forEach(p => {
                    p.CardValue = -2147483648;
                });
                this.sessionModel.Session.AllCardsSubmitted = false;
                resolve();
            } else {
                reject('no session object found');
            }
        });
    }
}
