import {Component, ViewChildren, QueryList, AfterViewInit} from '@angular/core';
import { Card } from '../../Atoms/Card/Card';
import { SessionService } from '../../../Services/SessionService';
import { SessionModel } from '../../../Models/SessionModel';
import { Log } from '../../../Framework/System/Log';

@Component({
    selector: 'x-playerboard',
    templateUrl: 'PlayerBoard.html',
    styleUrls: ['PlayerBoard.scss']
})
export class PlayerBoard implements AfterViewInit {
    @ViewChildren(Card)
    private _cards: QueryList<Card>;

    constructor(private sessionService: SessionService, protected sessionModel: SessionModel) {
    }

    ngAfterViewInit() {
        this._cards.forEach(card => card.OnClick.subscribe(this.CardClickedCallback.bind(this)));
    }

    CardClickedCallback(card: Card) {
        Log.debug('[PlayerBoard] Card clicked');
        this._cards.forEach(c => c.Selected = false);
        card.Selected = true;
        this.sessionService.SetCardValue(card.Value).then(data => {
            Log.debug('[PlayerBoard] SetCardValue Succeeded');
        }).catch(data => {
            Log.error('[PlayerBoard] CardClickedCallback Error', data);
            this.sessionService.SetCardValue(card.Value); // retry
        });
    }
}
