﻿export class Player {
    public ID: string;
    public Name: string;
    public CardValue: number;

    public static GetMock(): Player {
        const player: Player = new Player();
        player.ID = 'ABF7OaW4o0mBx4MkS8oD5n';
        player.Name = 'HansPeter';
        player.CardValue = 8;
        return player;
    }
}
