﻿import { Component, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';

@Component({
    selector: 'x-validationmessage',
    template: `<div *ngIf="Control.touched && !Control.valid && Control.errors[ErrorProperty] && If"><ng-content></ng-content></div>`,
    styleUrls: ['ValidationMessage.scss']
})
export class ValidationMessage {
    @Input('Control') Control: any;
    @Input('ErrorProperty') ErrorProperty: string;
    @Input('If') If = true;
}
