﻿import { Component, Input, HostBinding, ChangeDetectorRef, OnInit } from '@angular/core';

@Component({
    selector: 'x-leaf',
    template: `<ng-content></ng-content>`,
    styleUrls: ['Leaf.scss']
})
export class Leaf implements OnInit {
    @Input() Alternative: boolean = false;
    @HostBinding('class.leaf--alternative') _alternativeClass: boolean;

    ngOnInit() {
        this._alternativeClass = this.Alternative;
      }
}
