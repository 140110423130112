import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'x-largebutton',
    template: `<button [title]="Title" [type]="Type" (click)="OnClickEvent($event)" [disabled]="Disabled">{{Value}}</button>`,
    styleUrls: ['LargeButton.scss']
})
export class LargeButton {
    private _elementType: string;
    @ViewChild('button') ButtonElement: HTMLButtonElement;
    @Input('Value') Value = 'x-button';
    @Input('Title') Title = '';
    @Input('Type') Type = 'button';
    @Input('Disabled') Disabled = false;
    @Output('OnClick') OnClick = new EventEmitter();

    constructor(private elementRef: ElementRef) {
        this._elementType = elementRef.nativeElement.nodeName.toLowerCase();
        if (this._elementType === 'x-largebutton') {
            // TODO: Left as example of how to determine the element type of root component element
        }
    }

    private OnClickEvent($event) {
        this.OnClick.emit($event);
    }
}
