import {Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from '../../Services/SessionService';
import { SessionModel } from '../../Models/SessionModel';

@Component({
    selector: 'session-page',
    templateUrl: 'SessionPage.html',
    styleUrls: ['SessionPage.scss']
})
export class SessionPage implements OnInit {
    private FID = '';
    private FName = '';
    private FPlayerID = '';
    private FPlayerSession: any;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private sessionService: SessionService,
                protected sessionModel: SessionModel) {
    }

    ngOnInit() {
        this.FID = this.route.snapshot.params['id'];
        this.FPlayerID = this.route.snapshot.params['playerid'];
        this.getSession();
        setInterval(() => {
          if (this.sessionService.transportType === 'longPolling') {
            this.getSession();
          }
        }, 2000);
    }

    getSession() {
      this.sessionService.GetSession(this.FID, this.FPlayerID).then(data => {
        if (data) {
          this.FPlayerSession = data;
          this.FName = this.FPlayerSession.Player.Name;
        } else {
          this.router.navigate(['/']);
        }
      });
    }

    get isSubmitted() {
      return this.sessionModel.Session && this.sessionModel.Session.AllCardsSubmitted;
    }
}
