﻿import { Player } from './Player';

export class Session {
    public ID: string;
    public Code: string;
    public Created: Date;
    public LastModified: Date;
    public AllCardsSubmitted: boolean;
    public Players: Player[];

    public static GetMock(): Session {
        const session: Session = new Session();
        session.ID = '1ZF6OaW4o0mKx4MkS8oD2w';
        session.Code = 'H5F9K';
        session.Created = new Date();
        session.LastModified = new Date();
        session.AllCardsSubmitted = false;
        session.Players = new Array<Player>();
        //session.Players.push(Player.GetMock());
        const player2: Player = Player.GetMock();
        player2.CardValue = 1;
        player2.ID = '2BF6OaW4o0mKx4MkS8oD2k'
        player2.Name = 'Gertrud';
        //session.Players.push(player2);
        return session;
    }
}
