import {Component, ViewChild, ChangeDetectorRef, OnInit} from '@angular/core';
import { Log } from '../../Framework/System/Log';
import {TextBox} from '../../Components/Atoms/TextBox/TextBox';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Config} from '../../Config';
import {SessionService} from '../../Services/SessionService';

@Component({
    selector: 'join-page',
    templateUrl: 'JoinPage.html',
    styleUrls: ['JoinPage.scss']
})
export class JoinPage implements OnInit {
  private IsMocked = false;
  private IsCodePopulated = false;
  private JoinForm: FormGroup;
  private NameControl: FormControl;
  private CodeControl: FormControl;
  @ViewChild(TextBox) FSessionCode: TextBox;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private sessionService: SessionService) {
    this.IsMocked = Config.ClientSideMocked;
    this.CodeControl = new FormControl('', [Validators.required, Validators.pattern('\\s*[0-9A-Za-z]+\\s*')]);
    this.NameControl = new FormControl('', [Validators.required]);

    this.JoinForm = new FormGroup({
      Code: this.CodeControl,
      Name: this.NameControl
    });
  }

  ngOnInit() {
    const code = this.route.snapshot.params['code'];
    if (code) {
      this.IsCodePopulated = true;
      this.CodeControl.setValue(code);
    }
  }

  public FormSubmitted($event) {
    Log.info('[JoinPage] FormSubmitted');
    if (!this.JoinForm.valid) {
      Log.info('[JoinPage] Form is not validating: ', this.CodeControl.errors);
      return;
    }
    this.sessionService.JoinSession($event.Code.trim(), $event.Name.trim()).then(data => {
      if (data == null) {
        this.CodeControl.setErrors({ invalid: true });
        return;
      }
      Log.info('[JoinPage] JoinSession', data);
      this.router.navigate(['/session', data.Session.ID, data.Player.ID]);
    }).catch(data => {
      this.CodeControl.setErrors({ invalid: true });
      Log.error('[JoinPage] JoinSession Error', data);
    });
  }
}
