import { Component } from '@angular/core';
import { SessionModel } from '../../../Models/SessionModel';

@Component({
    selector: 'x-hostboard',
    templateUrl: 'HostBoard.html',
    styleUrls: ['HostBoard.scss']
})
export class HostBoard {

    constructor(private sessionModel: SessionModel) {
    }

    get noPlayers() {
      if (!this.sessionModel.Session) {
        return true;
      }
      return this.sessionModel.Session.Players.length === 0;
    }
}
