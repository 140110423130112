import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { App } from './App';
import { Config } from './Config';

// Injectables
import { SessionService } from './Services/SessionService';
import { MockSessionService } from './Services/MockSessionService';
import { SessionModel } from './Models/SessionModel';

// Components
import { Button } from './Components/Atoms/Button/Button';
import { LargeButton } from './Components/Atoms/LargeButton/LargeButton';
import { Card } from './Components/Atoms/Card/Card';
import { Leaf } from './Components/Atoms/Leaf/Leaf';
import { Logo } from './Components/Atoms/Logo/Logo';
import { TextBox } from './Components/Atoms/TextBox/TextBox';
import { ValidationMessage } from './Components/Atoms/ValidationMessage/ValidationMessage';
import { HostBoard } from './Components/Molecules/HostBoard/HostBoard';
import { HostCard } from './Components/Molecules/HostCard/HostCard';
import { PlayerBoard } from './Components/Molecules/PlayerBoard/PlayerBoard';

// Pages
import { HomePage } from './Pages/Home/HomePage';
import { SessionPage } from './Pages/Session/SessionPage';
import { HostPage } from './Pages/Host/HostPage';
import { JoinPage } from './Pages/Join/JoinPage';


if (document.querySelector('app-pokerli').getAttribute('mocked') === 'true') {
    Config.ClientSideMocked = true;
  }

const Routes = [
    { path: '', component: HomePage, pathMatch: 'full' },
    { path: 'session/:id/:playerid', component: SessionPage },
    { path: 'host/:id/:code', component: HostPage },
    { path: 'join', component: JoinPage },
    { path: 'join/:code', component: JoinPage }
];

@NgModule({
    declarations: [
      App,
      HomePage,
      SessionPage,
      HostPage,
      JoinPage,
      Button,
      LargeButton,
      Card,
      Leaf,
      Logo,
      TextBox,
      ValidationMessage,
      HostBoard,
      HostCard,
      PlayerBoard
    ],
    imports: [
      BrowserModule,
      RouterModule,
      RouterModule.forRoot(Routes),
      FormsModule,
      ReactiveFormsModule
    ],
    providers: [
        {
            provide: APP_BASE_HREF,
            useValue: '/'
        },
        SessionModel,
        Config.ClientSideMocked ? { provide: SessionService, useClass: MockSessionService } : SessionService,
    ],
    bootstrap: [App]
})
export class AppModule {
}
