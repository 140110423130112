﻿import { Session } from './Session';
import { Player } from './Player';

export class PlayerSession {
    public Session: Session;
    public Player: Player;

    public static GetMock(): PlayerSession {
        const session: Session = Session.GetMock();
        const player: Player = session.Players[0];
        const playerSession: PlayerSession = new PlayerSession();
        playerSession.Session = session;
        playerSession.Player = player;
        return playerSession;
    }
}
