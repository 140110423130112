import { Component } from '@angular/core';
import { Log } from '../../Framework/System/Log';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Config} from '../../Config';
import {SessionService} from '../../Services/SessionService';

@Component({
    selector: 'home-page',
    templateUrl: 'HomePage.html',
    styleUrls: ['HomePage.scss']
})
export class HomePage {
  private FTestCode = '7RALR';
  private IsMocked = false;

  constructor(private router: Router, private fb: FormBuilder, private sessionService: SessionService) {
    this.IsMocked = Config.ClientSideMocked;
  }

  public NavigateToHostPage() {
    Log.info('[HomePage] NavigateToHostPage');
    const session = this.sessionService.GetActiveSession();
    if (session) {
      Log.info('[HomePage] Navigating to existing session');
      this.router.navigate(['/host', session.ID, session.Code]);
      return;
    }
    this.sessionService.CreateSession().then(data => {
      this.router.navigate(['/host', data.ID, data.Code]);
    }).catch(data => {
      Log.error('[HomePage] NavigateToHostPage Error', data);
    });
  }

  public NavigateToJoinPage() {
    Log.info('[HomePage] NavigateToJoinPage');
    this.router.navigate(['/join']);
  }

  public CreateTestSession() {
    Log.info('[HomePage] CreateTestSession');
    this.sessionService.CreateSession().then(r => {
      this.sessionService.JoinSession(r.Code, 'John Doe').then(data => {
        this.router.navigate(['/session', data.Session.ID, data.Player.ID]);
      }).catch(data => {
        Log.error('[HomePage] JoinTestSession Error', data);
      });
    }).catch(data => {
      Log.error('[HomePage] CreateTestSession Error', data);
    });
  }

  public CreateTestClient() {
    Log.info('[HomePage] CreateTestClient');
    this.sessionService.JoinSession(this.FTestCode, 'John Doe').then(data => {
      this.router.navigate(['/session', data.Session.ID, data.Player.ID]);
    }).catch(data => {
      Log.error('[HomePage] CreateTestClient Error', data);
    });
  }
}
