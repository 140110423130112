/* tslint:disable:no-console */

export enum LogLevel {
    Debug = 1,
    Info = 2,
    Warning = 3,
    Error = 4,
    None = 5
  }

export class Log {
  public static logLevel: LogLevel = LogLevel.Debug;

  public static info(message?: any, ...optionalParams: any[]): void {
    if (this.logLevel <= LogLevel.Info) {
      if (optionalParams.length > 0) {
        console.info(message, optionalParams);
      } else {
        console.info(message);
      }
    }
  }

  public static debug(message?: any, ...optionalParams: any[]): void {
    if (this.logLevel <= LogLevel.Debug) {
      if (optionalParams.length > 0) {
        console.debug(message, optionalParams);
      } else {
        console.debug(message);
      }
    }
  }

  public static warn(message?: any, ...optionalParams: any[]): void {
    if (this.logLevel <= LogLevel.Warning) {
      if (optionalParams.length > 0) {
        console.warn(message, optionalParams);
      } else {
        console.warn(message);
      }
    }
  }

  public static error(message?: any, ...optionalParams: any[]): void {
    if (this.logLevel <= LogLevel.Error) {
      if (optionalParams.length > 0) {
        console.error(message, optionalParams);
      } else {
        console.error(message);
      }
    }
  }
}
