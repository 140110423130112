import { Component, OnInit  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../../Services/SessionService';
import { SessionModel } from '../../Models/SessionModel';
import { Log } from '../../Framework/System/Log';

@Component({
    selector: 'host-page',
    templateUrl: 'HostPage.html',
    styleUrls: ['HostPage.scss']
})
export class HostPage implements OnInit {
    private FID: string;
    private FCode: string;
    public joinLink: string;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private sessionService: SessionService,
                private sessionModel: SessionModel) {
    }

    ngOnInit() {
        this.FID = this.route.snapshot.params['id'];
        this.FCode = this.route.snapshot.params['code'];
        Log.debug('[HostPage] ngOnInit', this.FID, this.FCode);
        this.joinLink = 'https://pokerli.net/join/' + this.FCode;
        this.sessionService.GetSession(this.FID).catch((e) => {
            this.sessionService.CreateSession(this.FID, this.FCode).then(data => {
                Log.debug('[HostPage] ngOnInit::CreateSession', this.FID, this.FCode);
            });
        });
        setInterval(() => {
          if (this.sessionService.transportType === 'longPolling') {
            this.sessionService.GetSession(this.FID);
          }
        }, 2000);
    }

    NextEstimation() {
        this.sessionService.Reset(this.FID);
    }

    ForceShow() {
        this.sessionService.ForceShow(this.FID);
    }

    EndSession() {
        this.sessionService.EndSession();
        this.router.navigate(['/']);
    }

    get noPlayers() {
      if (!this.sessionModel.Session) {
        return true;
      }
      return this.sessionModel.Session.Players.length === 0;
    }
}
