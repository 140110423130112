﻿import { Injectable, ApplicationRef  } from '@angular/core';
import { BaseHub } from '../Framework/SignalR/BaseHub';
import { SessionModel } from '../Models/SessionModel';
import { Log } from '../Framework/System/Log';
import { Session } from '../Models/Session';

@Injectable()
export class SessionService extends BaseHub {

    constructor(protected sessionModel: SessionModel, protected appRef: ApplicationRef ) {
        super('sessions', '');
    }

    protected RegisterOnServerEvents(): void {
        this._proxy.on('SessionUpdate', (data) => this.OnSessionUpdate(data));
    }

    public OnSessionUpdate(data: any) {
        Log.info('[SessionService] OnSessionUpdate', data);
        this.sessionModel.Session = data;
        if (this.sessionModel.Player) {
            this.sessionModel.Session.Players.forEach(p => {
                if (p.ID === this.sessionModel.Player.ID) {
                    this.sessionModel.Player = p;
                }
            });
        }
      this.appRef.tick();
    }

    public GetActiveSession(): Session {
        return this.sessionModel.Session;
    }

    public EndSession(): void {
        this.sessionModel.Session = null;
    }

    public CreateSession(sessionID?: string, code?: string): Promise<any> {
        Log.info('[SessionService] CreateSession', sessionID, code);
        return new Promise<any>((resolve, reject) => {
            this.AwaitConnection().then(() => {
                if (sessionID !== undefined && code !== undefined) {
                    this._proxy.invoke('createSession', sessionID, code).then(r => {
                        Log.info('[SessionService] CreateSession Result::1', r);
                        this.sessionModel.Session = r;
                      this.appRef.tick();
                        resolve(r);
                    }).catch(e => {
                        Log.error('[SessionService] CreateSession Error::1', e);
                        reject(e);
                    });
                } else {
                    this._proxy.invoke('createSession').then(r => {
                        Log.info('[SessionService] CreateSession Result::2', r);
                        this.sessionModel.Session = r;
                      this.appRef.tick();
                        resolve(r);
                    }).catch(e => {
                        Log.error('[SessionService] CreateSession Error::2', e);
                        reject(e);
                    });
                }
            }).catch((e) => {
                Log.error('[SessionService] CreateSession::AwaitConnection Error', e);
                reject(e);
            });
        });
    }

    public GetSession(sessionID: string, playerSessionID?: string): Promise<any> {
        Log.info('[SessionService] GetSession', sessionID, playerSessionID);
        return new Promise<any>((resolve, reject) => {
            this.AwaitConnection().then(() => {
                if (playerSessionID !== undefined) {
                    this._proxy.invoke('getSession', sessionID, playerSessionID).then(r => {
                        Log.info('[SessionService] GetSession Result::1', r);
                        if (r) {
                            this.sessionModel.Player = r.Player;
                            this.sessionModel.Session = r.Session;
                        }
                        this.appRef.tick();
                        resolve(r);
                    }).catch(e => {
                        Log.error('[SessionService] GetSession Error::1', e);
                        reject(e);
                    });
                } else {
                    this._proxy.invoke('getSession', sessionID).then(r => {
                        Log.info('[SessionService] GetSession Result::2', r);
                        this.sessionModel.Session = r;
                        this.appRef.tick();
                        resolve(r);
                    }).catch(e => {
                        Log.error('[SessionService] GetSession Error::2', e);
                        reject(e);
                    });
                }
            }).catch((e) => {
                Log.error('[SessionService] GetSession::AwaitConnection Error', e);
                reject(e);
            });
        });
    }

    public SetCardValue(value: number): Promise<any> {
        Log.info('[SessionService] SetCardValue', value);
        return new Promise<any>((resolve, reject) => {
            this.AwaitConnection().then(() => {
                if (this.sessionModel.Player) {
                    this._proxy.invoke('setCardValue', this.sessionModel.Player.ID, value).then(r => {
                        Log.info('[SessionService] SetCardValue Result', r);
                        this.sessionModel.Player = r.Player;
                        this.sessionModel.Session = r.Session;
                        this.appRef.tick();
                        resolve();
                    }).catch(e => {
                        Log.error('[SessionService] SetCardValue Error', e);
                        reject(e);
                    });
                } else {
                    Log.info('[SessionService] SetCardValue SessionModel', this.sessionModel);
                    reject('no player object found');
                }
            }).catch((e) => {
                Log.error('[SessionService] SetCardValue::AwaitConnection Error', e);
                reject(e);
            });
        });
    }

    public JoinSession(code: string, name: string): Promise<any> {
        Log.info('[SessionService] JoinSession', code, name);
        return new Promise<any>((resolve, reject) => {
            this.AwaitConnection().then(() => {
                this._proxy.invoke('joinSession', code, name).then(r => {
                    Log.info('[SessionService] JoinSession Result', r);
                    if (r != null) {
                        this.sessionModel.Player = r.Player;
                        this.sessionModel.Session = r.Session;
                    }
                    this.appRef.tick();
                    resolve(r);
                }).catch(e => {
                    Log.error('[SessionService] JoinSession Error', e);
                    reject(e);
                });
            }).catch((e) => {
                Log.error('[SessionService] JoinSession::AwaitConnection Error', e);
                reject(e);
            });
        });
    }

    public Reset(sessionID: string): Promise<any> {
        Log.info('[SessionService] Reset', sessionID);
        return new Promise<any>((resolve, reject) => {
            this.AwaitConnection().then(() => {
                this._proxy.invoke('reset', sessionID).then(r => {
                    Log.info('[SessionService] Reset Result', r);
                    this.sessionModel.Session = r;
                    this.appRef.tick();
                    resolve();
                }).catch(e => {
                    Log.error('[SessionService] Reset Error', e);
                    reject(e);
                });
            }).catch((e) => {
                Log.error('[SessionService] Reset::AwaitConnection Error', e);
                reject(e);
            });
        });
    }

    public ForceShow(sessionID: string): Promise<any> {
        Log.info('[SessionService] ForceShow', sessionID);
        return new Promise<any>((resolve, reject) => {
            this.AwaitConnection().then(() => {
                this._proxy.invoke('forceShow', sessionID).then(r => {
                    Log.info('[SessionService] ForceShow Result', r);
                    this.sessionModel.Session = r;
                    this.appRef.tick();
                    resolve();
                }).catch(e => {
                    Log.error('[SessionService] ForceShow Error', e);
                    reject(e);
                });
            }).catch((e) => {
                Log.error('[SessionService] ForceShow::AwaitConnection Error', e);
                reject(e);
            });
        });
    }
}
