﻿import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, OnInit } from '@angular/core';
import { Config } from '../../../Config';

@Component({
    selector: 'x-card',
    template: `<div [class]="Selected && !Back ? 'card__box card__box--selected' : 'card__box'" (click)="OnClickEvent($event)"><img [src]="GetSourcePath()" [title]="GetTitle()" /></div>`,
    styleUrls: ['Card.scss']
})
export class Card {
    @Input() Value: number;
    @Input() Selected: boolean;
    @Input() Back: boolean;
    @Output('OnClick') OnClick = new EventEmitter<Card>();

    constructor(private rootElement: ElementRef) {
    }

    private OnClickEvent($event) {
        this.OnClick.emit(this);
    }

    private GetSourcePath(): string {
        const selected: string = this.Selected ? '_s' : '';
        if (this.Back) {
            return Config.BasePath + '/assets/Cards/back' + selected + '.png?v=1';
        }
        return Config.BasePath + '/assets/Cards/' + this.GetStringValue() + selected + '.png?v=1';
    }

    private GetTitle(): string {
        if (this.Back) {
            return 'Card Back';
        }
        return 'Card Value: ' + this.GetStringValue();
    }

    private GetStringValue() {
        if (this.Value === -1 || this.Value === undefined) {
            return 'question';
        }
        if (this.Value === -2) {
            return 'coffee';
        }
        return this.Value.toString();
    }
}
